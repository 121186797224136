
import Swal from "sweetalert2/dist/sweetalert2.js";
import Country from "@/components/ABilling/CounrtyComponent.vue";
import State from "@/components/ABilling/StateComponent.vue";
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
  watch,
  nextTick,
} from "vue";

import { getOrganization } from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { translateDate } from "@/modules/utility/date-utility";
import useVuelidate from "@vuelidate/core";
import { email, helpers, maxLength, required } from "@vuelidate/validators";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { getDictionary } from "@/api/dictionaries.api";
import { getPatient } from "@/api/patient.api";
import PhoneNumberComponent from "@/components/ABilling/PhoneNumberComponent.vue";
import Multiselect from "@vueform/multiselect";
import {
  addGuarantor,
  checkGuarantorOverlap,
  deleteGuarantor,
  getGuarantor,
  updateGuarantor,
} from "@/api/guarantor.api";

export interface Data {
  isDirty: boolean;
  modal: boolean;
  previousRoute?: any;
  isLoaded: boolean;
  previousGuarantor?: any;
  guarantor?: any;
  patient?: any;
  checkResults?: any;
  dict: {
    relationshipToPatient: any[];
    boolListString: { code: string; name: string }[];
  };
}
export default defineComponent({
  name: "Guarantor Page",
  components: { Country, State, PhoneNumberComponent ,Multiselect},
  props: ["guarantorId", "patientId", "loadFromCache", "backToPayer", "query"],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      (vm as any).data.previousRoute = from;
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.data.isDirty) {
      let text = "Are you sure you want to leave without saving changes?";
      Swal.fire({
        title: text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else if (result.isDenied) {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  setup(props) {
    let organizationId = ref<string | null>("");
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    let data = reactive<Data>({
      isDirty: false,
      modal: false,
      isLoaded: false,
      patient: undefined,
      guarantor: undefined,
      dict: {
        relationshipToPatient: [],
        boolListString: [
          { code: "Y", name: "Yes" },
          { code: "N", name: "No" },
        ],
      },
    });

    watch(
      () => data.guarantor,
      (currentValue, oldValue) => {
        if (!data.previousGuarantor.country)  data.previousGuarantor.country = "1";
        if (!currentValue.country)  currentValue.country = "1";
        if (
          currentValue &&
          data.previousGuarantor &&
          JSON.stringify(currentValue) != JSON.stringify(data.previousGuarantor)
        ) {
          data.isDirty = true;
        }
      },
      { deep: true }
    );

    watch(
      () => props.guarantorId,
      () => {
        router.go(0);
      }
    );

    const isPhone = helpers.regex(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im); // eslint-disable-line

    const guarantorRules = {
      guarantor: {
        relationshipToPatient: { required:helpers.withMessage("Required", required) },
        firstName: { required:helpers.withMessage("Required", required), maxLength: maxLength(35) },
        middleName: { maxLength: maxLength(35) },
        lastName: { required:helpers.withMessage("Required", required), maxLength: maxLength(60) },
        address1: { required:helpers.withMessage("Required", required), maxLength: maxLength(55) },
        address2: { maxLength: maxLength(55) },
        email: { email, maxLength: maxLength(256) },
        mobile: {
          required:helpers.withMessage("Required", required),
          isPhone: helpers.withMessage("Phone number is invalid", isPhone),
        },
        work: {
          isPhone: helpers.withMessage("Phone number is invalid", isPhone),
        },
        home: {
          isPhone: helpers.withMessage("Phone number is invalid", isPhone),
        },
        city: { required:helpers.withMessage("Required", required), maxLength: maxLength(30) },
        state: { required:helpers.withMessage("Required", required), maxLength: maxLength(10) },
        zip: { required:helpers.withMessage("Required", required), maxLength: maxLength(15) },
      },
    };

    let v$ = useVuelidate(guarantorRules, data as never);

    onMounted(async () => {
      setCurrentPageTitle("Guarantor");
      organizationId.value = getOrganization();
      data.dict.relationshipToPatient = await getDictionary(10, "");
      data.patient = await getPatient(props.patientId);

      if (window.localStorage.getItem("AddGuarantor") !== null && route.query.loadFromCache) {
        const guarantor = JSON.parse(window.localStorage.getItem("AddGuarantor") as string);
        data.guarantor = guarantor;
      }
      else if (props.guarantorId) {
        data.guarantor = await getGuarantor(props.guarantorId);
      }
      else {
        data.guarantor = {
          id: null,
          patientId: props.patientId,
          country: 1,
          state: null,
          relationshipToPatient: "18",
          sameAsPatient: true,
        };
        relationshipChanged();
      }

      data.previousGuarantor = JSON.parse(JSON.stringify(data.guarantor));
      data.isLoaded = true;
    });

    async function cancel() {
      if (route.query.backToPayer != undefined) {
        router.push({
          path:
            "/patientAccounts/patient/" +
            props.patientId +
            "/guarantor/" +
            route.query.backToPayer,
          query: { loadFromCache: "true" },
        });
        return;
      }
      router.push({
        path: "/patientAccounts/patient/" + props.patientId + "/guarantorList",
      });
    }

    async function addItem() {
      const result = await v$.value.guarantor.$validate();
      if (result) {
        const checkResults = await checkGuarantorOverlap(data.guarantor);
        if (checkResults.error) {
          data.checkResults = checkResults;
          data.modal = true;
          return;
        }
        const guarantorId = await addGuarantor(data.guarantor);
        data.previousGuarantor = JSON.parse(JSON.stringify(data.guarantor));
        window.localStorage.removeItem("AddGuarantor");
        if (guarantorId) {
          data.isDirty = false;
          router.push({
            path: "/patientAccounts/patient/" + data.patient.id + "/guarantorList",
          });
        }
      }
    }

    async function updateItem() {
      const result = await v$.value.guarantor.$validate();
      if (result) {
        const checkResults = await checkGuarantorOverlap(data.guarantor);
        if (checkResults.error) {
          data.checkResults = checkResults;
          data.modal = true;
          return;
        }
        await updateGuarantor(data.guarantor);
        data.previousGuarantor = JSON.parse(JSON.stringify(data.guarantor));

        data.isDirty = false;

        if (route.query.backToPayer != undefined) {
          router.push({
            path:
              "/patientAccounts/patient/" +
              props.patientId +
              "/guarantor/" +
              route.query.backToPayer,
            query: { loadFromCache: "true" },
          });
          return;
        }

        router.push({
          path: "/patientAccounts/patient/" + props.patientId + "/guarantorList",
        });
      }
    }

    async function deleteItem() {
      await deleteGuarantor(data.guarantor);
    }

    async function closeCancel() {
      //if (!data.createNewMode)
      //  data.guarantor = await getGuarantor(data.guarantor?.id);
    }

    async function sameAsPatientChanged() {
      if (data.guarantor) {
        if (data.guarantor?.sameAsPatient) {
          const patient = data.patient;
          if (patient) {
            data.guarantor.address1 = patient.address;
            data.guarantor.address2 = patient.address2;
            data.guarantor.city = patient.city;
            data.guarantor.country = patient.country ?? "1"; // default to US
            data.guarantor.state = patient.state;
            data.guarantor.zip = patient.zip;
            await nextTick();
          }
        }
      }
    }

    async function relationshipChanged() {
      await nextTick();
      if (data.guarantor) {
        if (data.guarantor?.relationshipToPatient == "18") {
          const patient = data.patient;
          if (patient) {
            data.guarantor.firstName = patient.firstName;
            data.guarantor.middleName = patient.middleName;
            data.guarantor.lastName = patient.lastName;
            data.guarantor.home = patient.homePhone;
            data.guarantor.work = patient.officePhone;
            data.guarantor.mobile = patient.mobilePhone;

            data.guarantor.email = patient.email;
            data.guarantor.address1 = patient.address;
            data.guarantor.address2 = patient.address2;
            data.guarantor.city = patient.city;
            data.guarantor.country = patient.country ?? "1"; // default to US
            data.guarantor.state = patient.state;
            data.guarantor.zip = patient.zip;
            await nextTick();

            data.guarantor.sameAsPatient = true;
          }
        } else {
          data.guarantor.firstName = undefined;
          data.guarantor.middleName = undefined;
          data.guarantor.lastName = undefined;
          data.guarantor.home = undefined;
          data.guarantor.work = undefined;
          data.guarantor.mobile = undefined;

          data.guarantor.email = undefined;
          data.guarantor.address1 = undefined;
          data.guarantor.address2 = undefined;
          data.guarantor.city = undefined;
          data.guarantor.zip = undefined;
          data.guarantor.country = "1";  // default to US
          data.guarantor.state = undefined;
          data.guarantor.sameAsPatient = false;
          await nextTick();
        }
      }
    }

    const maxStartDate = computed(() => {
      return translateDate(data.guarantor?.endDate);
    });

    const minEndDate = computed(() => {
      return translateDate(data.guarantor?.effectiveDate);
    });

    function selectItem(item) {
      data.modal = false;
      window.localStorage.setItem(
        "AddGuarantor",
        JSON.stringify(data.guarantor)
      );

      router.push({
        path: item.id,
        query: { backToPayer: props.guarantorId },
      });
    }

    const ifRelationshipSelf = computed(() => {
      return data.guarantor?.relationshipToPatient == "18";
    });

    return {
      organizationId,
      data,
      addItem,
      updateItem,
      deleteItem,
      cancel,
      closeCancel,
      relationshipChanged,
      sameAsPatientChanged,
      minEndDate,
      maxStartDate,
      selectItem,
      ifRelationshipSelf,
      v$,
    };
  },
});
